<template>
    <div class="row" v-if="order!=null">
        <div class="col-12">
            <div class="row">
                <div class="col-sm-12">
                    <p class="heading">Items ordered</p>
                    <!--<hr/>-->
                    <div class="table-responsive">
                        <table class="table table-bordered items">

                            <tr>
                                <th class="">#</th>
                                <th class="">Item Name</th>
                                <th class="">Price</th>
                                <th class="">Quantity</th>
                                <th class="">Net Price</th>
                            </tr>

                            <tr :class="{'table-danger':item.inventory==null||item.inventory.quantity<item.quantity}"
                                v-for="(item,index) in order.items" v-bind:key="index">
                                <th>{{index + 1}}</th>
                                <td>{{item.item_name}}</td>
                                <td>{{item.price | currency}}</td>
                                <td>
                                    {{item.quantity}}
                                </td>
                                <td>{{(item.price * item.quantity).toFixed(2) | currency}}</td>
                            </tr>


                            <tr>
                                <th colspan="3" class="text-right">Total</th>
                                <th>{{order.quantity}}</th>
                                <th>{{order.price | currency}}</th>
                            </tr>
                            <tr>
                                <th colspan="4" class="text-right">Order Status</th>
                                <th>{{order.order_status}}</th>
                            </tr>

                            <tr>
                                <th colspan="4" class="text-right">Payment Status</th>
                                <th>{{order.payment_status}}</th>
                            </tr>
                            <tr>
                                <th colspan="4" class="text-right">Amount Paid</th>
                                <th>{{order.amount_paid | currency}}</th>
                            </tr>
                            <tr>
                                <th colspan="4" class="text-right">Payment Due</th>
                                <th>{{order.payment_due | currency}}</th>
                            </tr>


                        </table>
                    </div>

                    <p class="heading mt-3">Payment</p>
                    <div class="table-responsive">
                        <table class="table table-striped items">
                            <tr v-if="order.payment_due>0">
                                <td colspan="4">
                                    <OrderPayment @payment_done="$emit('payment_done')" :order="order"></OrderPayment>
                                </td>
                            </tr>

                        </table>
                    </div>

                    <PaymentList :payments="order.payments" v-if="order.payments.length>0"></PaymentList>


                    <p class="heading mt-3">{{order.ship_type_id == 1 ? 'Delivery' : 'Pickup'}} Information</p>
                    <div class="table-responsive">
                        <table class="table table-striped items">

                            <tr>
                                <td>Ship Type</td>
                                <td>{{order.ship_type}}</td>
                                <td>Ship Date</td>
                                <td>{{order.ship_date}}</td>
                            </tr>

                            <tr>
                                <td>Name</td>
                                <td>{{order.customer.full_name}}
                                </td>
                                <td>Phone</td>
                                <td>{{order.customer.customer.phone}}</td>
                            </tr>
                            <tr>
                                <td>Delivery Address</td>
                                <td colspan="3">
                                    {{order.delivery_address == null ? 'None' : order.delivery_address.full_address}}
                                </td>
                            </tr>

                        </table>
                    </div>
                </div>
            </div>
            <DeliverOrder :order_id="order.id" v-if="$auth.check('delivery') && order.status==4"
                          v-on:completed="$emit('completed')"></DeliverOrder>
            <Actions :order="order" :reason="order.reason"
                     v-on:rejected="$emit('rejected')"
                     v-on:approved="$emit('approved')"
                     v-on:warehouse_assigned="warehouseAssigned"
                     v-on:assigned="assigned"
                     v-on:completed="$emit('completed')"
                     v-on:sales_man_assigned="salesManAssigned"
            ></Actions>

            <p class="text-danger" v-if="cancelling_order">Cancelling order...</p>
            <input type="button" v-if="!cancelling_order && order.status!=7" class="btn btn-danger mt-2"
                   value="Cancel Order"
                   @click="cancel_order">

        </div>
    </div>

</template>
<script>

    import Actions from './OrderActions.vue';
    import DeliverOrder from './DeliverOrder.vue';
    import lists from '@/mixins/list';
    import {Paths} from '@/paths.js';

    import OrderPayment from '@/views/payment/OrderPayment';
    import PaymentList from '@/views/payment/PaymentList';
    export default{
        props: ['order'],
        mixins: [lists],
        components: {Actions, DeliverOrder, OrderPayment, PaymentList},
        data(){
            return {
                cancelling_order: false,
            }
        }, methods: {
            salesManAssigned(data){
                this.$emit('sales_man_assigned', data);
            },
            warehouseAssigned(data){
                this.$emit('warehouse_assigned', data);
            },
            assigned(data){

                this.$emit('assigned', data);
            },
            cancel_order(){
                if (!confirm('Do you want to cancel this order?')) {
                    return;
                }
                let app = this;
                app.cancelling_order = true;

                this.axios.post(Paths.order.cancel, {'order_id': app.order.id}).then(() => {
                    app.$emit('assigned');
                }).finally(() => app.cancelling_order = false
                );
            }
        },
        mounted(){
        }
    }
</script>

<style>
    .items, .items td, .items th {
        border: 1px solid #dddddd;
    }
</style>