<template>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label for="">Assign Order To Delivery Person</label>
                <select class="form-control" v-model="delivery_person_id">
                    <option value="0">Select</option>
                    <option v-for="(person,index) in delivery_persons" :value="person.id" v-bind:key="index">
                        {{person.full_name}}
                    </option>
                </select>
                <span class="text-danger">{{getError('delivery_person_id')}}</span>
                <span class="text-success" v-if="loading">Assigning driver...</span>
            </div>
            <input type="button" class="btn btn-outline-info btn-sm" v-if="!loading" value="Assign Order"
                   @click="assign()">
        </div>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    import forms from '@/forms';
    export default{
        extends: forms,
        props: ['order_id'],
        data(){
            return {
                delivery_persons: [],
                delivery_person_id: 0,
            };
        },
        methods: {
            loadPersons(){
                let app = this;
                this.axios.get(Paths.deliveryperson.index).then((response) => {
                    console.log(response.data);
                    app.delivery_persons = response.data;
                });
            },
            assign(){
                let app = this;
                app.startRequest();
                this.axios.post(Paths.order.assign, {
                    order_id: this.order_id,
                    delivery_person_id: this.delivery_person_id
                }).then((response) => {

                    app.$emit('assigned', response.data);
                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {
                    app.endRequest();
                });
            }
        },
        mounted(){
            this.loadPersons();
        }
    }
</script>