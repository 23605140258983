<template>
    <div class="row">
        <div class="col-12">
            <input type="button" class="btn btn-sm btn-outline-info" @click="complete" value="Complete Order">
        </div>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    import loader from '@/loader';
    export default{
        extends: loader,
        props: ['order_id'],
        methods: {
            complete(){
                if (!confirm('Do you want to complete status of order?')) {
                    return;
                }

                let app = this;
//                app.$emit('completed');
                app.startLoading();

                this.axios.post(Paths.order.complete, {order_id: this.order_id}).then(() => {
                    app.$emit('completed');
                }).finally(() => {
                    app.endLoading();
                });
            }
        }
    }
</script>