<template>
    <div class="row">
        <div class="col-sm-12" v-if="loading">
            <pulse-loader :loading="loading"></pulse-loader>

        </div>

        <div class="col-12" v-if="$auth.user().role=='admin'">
            <div class="row">
                <div class="col-12" v-if="order.salesman==null ">
                    <p class="heading">Assign to sales person</p>
                    <assign-to-salesman :order_id="order.id" v-on:assigned="salesManAssigned"></assign-to-salesman>

                </div>
                <div class="col-12" v-else="">
                    Salesman : <strong>{{order.salesman.user.full_name}}</strong>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row" v-if="order.salesman!=null">
                <div class="col-sm-12 text-danger" v-if="order.status==3">
                    <div class="alert alert-danger" v-if="reason!=null">Order Rejected because of following reason. "<i>{{reason}}</i>"
                    </div>
                    <!--<div class="alert alert-danger">Order Rejected</div>-->
                </div>
                <div class="col-sm-12" v-else-if="order.status==1 &&!loading">
                    <input type="button" class="btn btn-outline-info btn-sm mr-2" @click="approve"
                           value="Approve Order">
                    <input type="button" @click="show_reject_form=true" class="btn btn-danger btn-sm"
                           value="Reject Order">
                    <RejectOrder v-if="show_reject_form" v-on:rejected="$emit('rejected')"
                                 v-on:hide="show_reject_form=false;"
                                 :order_id="order.id"></RejectOrder>
                </div>

                <div class="col-sm-12" v-else-if="order.status==2||order.status==6">
                    <p class="text-success">Order Status: <strong>Approved</strong></p>


                </div>
                <div class="col-sm-12" v-if="order.status>1">
                    <AssignWarehouse :order="order" v-on:assigned="warehouseAssigned"></AssignWarehouse>
                    <AssignOrder v-if="show_assign && order.ship_type_id==1" :order_id="order.id" v-on:assigned="assigned" class="mt-3"></AssignOrder>
                </div>



                <div class="col-sm-12 text-success" v-if="order.status==4 && order.ship_type_id==1">

                    <div v-if="order.deliveries!=undefined && order.deliveries.length>0">
                        <p class="heading mt-3">Delivery Person Record</p>
                        <hr/>
                        <div class="table-responsive">
                            <table class="table">
                                <tr>
                                    <th class="border-top-0">#</th>
                                    <th class="border-top-0">Name</th>
                                    <th class="border-top-0">Assigned On</th>
                                    <td class="border-top-0">Cancel</td>
                                </tr>
                                <tr v-for="(delivery,index) in order.deliveries" v-bind:key="index">
                                    <th>{{index + 1}}</th>
                                    <td>{{delivery.delivery_person.full_name}}</td>
                                    <td>{{delivery.created_at}}</td>
                                    <td>
                                        <button v-if="delivery.is_cancelled==0" @click="cancel_delivery(index)"
                                                class="btn btn-sm btn-danger">Cancel
                                        </button>
                                        <p class="text-danger" v-else="">Cancelled</p>
                                    </td>
                                </tr>
                            </table>
                        </div>

                    </div>


                    <DeliverOrder :order_id="order.id" v-if="$auth.check('delivery')"
                                  v-on:completed="$emit('completed')"></DeliverOrder>


                </div>
                <div class="col-sm-12 " v-else-if="order.status==6"></div>

                <div class="col-sm-12 text-danger" v-if="order.status==5">
                    <div class="alert alert-success">
                        Order Delivered By <strong>{{order.deliveries[order.deliveries.length - 1].delivery_person.full_name}}</strong>
                        on <strong>{{order.updated_at}}</strong>
                    </div>

                </div>
            </div>
        </div>


    </div>

</template>
<script>

    import RejectOrder from './RejectOrder.vue';
    import AssignOrder from './AssignOrder.vue';
    import AssignWarehouse from './AssignWarehouse.vue';

    import AssignToSalesman from './AssignToSalesman.vue';
    import {Paths} from '@/paths';
    import Loader from '@/loader';

    export default{
        extends: Loader,
        components: {RejectOrder, AssignToSalesman, AssignWarehouse, AssignOrder},
        props: ['order', 'reason'],
        data(){
            return {
                show_reject_form: false,
                allow_salesman_assignment: false,
//                show_assign: false,
            }
        },
        methods: {

            approve(){
                let app = this;
                this.startLoading();
                this.axios.post(Paths.order.approve, {order_id: this.order.id, status: 2}).then(() => {
                    app.$emit('approved');

                }).finally(() => {
                    app.endLoading();
                });

            }, salesManAssigned(data){
                this.$emit('sales_man_assigned', data);
            },
            warehouseAssigned(data){
                this.$emit('warehouse_assigned', data);
            },
            assigned(data){
                this.$emit('assigned', data);

            },

            cancel_delivery(index){
                let delivery_id = this.order.deliveries[index].id;
                let app = this;
                this.axios.post(Paths.order.cancel_delivery, {'id': delivery_id}).then((response) => {
                    if (response.status == 200) {
                        app.order.deliveries[index].is_cancelled = true;
                    }
                })


            }

        },

        computed: {
            show_assign: function () {
                if (this.order == null) {
                    return false;
                }
                if (this.order.deliveries.length == 0) {
                    return true;
                }
                let app = this;
                for (let i = 0; i < this.order.deliveries.length; i++) {
                    if (app.order.deliveries[i].is_cancelled == false) {
                        return false;
                    }
                }
                return true;
            }
        },

        watch: {

            order: function (value) {
                if (value.deliveries.length == 0) {
//                    this.show_assign = this.order.warehouse_staff != null && (this.order.status == 2 || this.order.status == 6 || this.order.status == 4);
                    return;
                }


            }
        },
        mounted(){

        }
    }
</script>