<template>
    <div class="row">
        <div class="col-sm-6 ">
            <div class="form-group">
                <label>Select Salesman</label>
                <select class="form-control form-control-sm" v-model="user_id">
                    <option value="0">Select</option>
                    <option v-for="man in salesmen" v-bind:key="man.id" :value="man.id">{{man.full_name}}</option>
                </select>
                <span class="text-danger">{{getError('user_id')}}</span>
            </div>
            <input type="button" @click="assign" class="btn btn-sm btn-outline-info shadow" value="Assign ">
        </div>
        <axios :url="url" :data="{user_id:user_id,order_id:order_id}" :request="req_type" v-on:success="onSuccess"
               v-on:error="onError"
               :trigger="trigger"></axios>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    import Forms from '@/forms';
    import Axios from '@/views/actions/Axios';
    export default{
        extends: Forms,
        props: ['order_id'],
        components: {Axios},
        data(){
            return {
                salesmen: [],
                url: null,
                trigger: '',
                req_type: 'post',
                user_id: 0,
            }
        }, methods: {
            setUrlForSalesMen(){
                this.url = Paths.salesman.index;
                this.req_type = 'get';
            },
            setUrlForAssignment(){
                this.url = Paths.order.salesman.assign;
                this.req_type = 'post';
            },
            isSalesManUrl(){
                return this.url == Paths.salesman.index;
            },
            onSuccess(response){
                this.trigger = false;
                console.log('success');
                console.log(response);
                if (this.isSalesManUrl()) {
                    console.log('Setting sales man response');
                    return this.onSalesMen(response);
                }
                this.onAssigned(response.data);
            },
            onAssigned(){

                let selected_salesman = this._.find(this.salesmen, {'id': this.user_id});

                selected_salesman.user = selected_salesman;
                this.$emit('assigned', selected_salesman);
            },
            onError(error){
                this.trigger = false;
                this.setErrors(error.response);
            },
            onSalesMen(data){
                this.salesmen = data;

            },
            loadSalesMen(){
                this.setUrlForSalesMen();
                this.trigger = true;

            },
            assign(){
                this.setUrlForAssignment();
                this.trigger = true;
            }
        }, mounted(){
            this.loadSalesMen();
        }
    }
</script>