<template>
    <div class="row">
        <div class="col-sm-12">

            <div class="form-group">
                <label for="">Total Amount $</label>
                <input type="text" disabled="disabled" class="form-control" :value="order.payment_due">
            </div>
            <div class="form-group">
                <label for="">Amount Paid</label>
                <input type="text" class="form-control" v-model="input.amount_paid"
                       onkeypress="return event.key === 'Enter'|| event.key==='.' || (Number(event.key) >= 0 && Number(event.key) <= 9)">
            </div>

            <div class="form-group">
                <label for="">Balance</label>
                <input type="text" v-model="balance" readonly="readonly" class="form-control">
            </div>

            <div class="form-group">
                <label for="">Payment Date</label>
                <input type="date" class="form-control" v-model="input.payment_date">
            </div>

            <button class="btn btn-outline-info" v-if="input.amount_paid>0" @click="save_payment">Save Payment</button>
        </div>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    export default{
        props: ['order'],
        data(){
            return {
                input: {
                    user_id: 0,
                    orders: [],
                    payment_date: '',
                    amount_paid: 0,


                }

            }
        },
        computed: {
            balance: function () {
                if (this.input.amount_paid == 0) {
                    return 0;
                }
                return (this.order.payment_due - this.input.amount_paid).toFixed(2);
            },
            partial_payment: function () {
                return this.order.payment_due > this.input.amount_paid;
            }
        },
        watch: {},
        methods: {
            get_date(){
                return new Date().toISOString().split('T')[0];
            },
            save_payment(){
                let app = this;

                app.input.balance = app.balance;
                app.input.orders.push({
                    order_id: app.order.id,
                    amount_paid: app.input.amount_paid,
                    paid_by: app.order.customer_id,
                    is_partial: app.partial_payment,
                    payment_date: app.input.payment_date,
                });


                this.axios.post(Paths.order.save_payment, app.input).then(() => {
                    app.$emit('payment_done');
                })


            }
        },
        mounted(){
            this.input.user_id = this.order.customer_id;
            this.input.payment_date = this.get_date();

        }

    }
</script>