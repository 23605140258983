<template>
    <div class="row">
        <div class="col-12" v-if="order.warehouse_staff==null">
            <div class="form-group">
                <label for="">Assign Order To Warehouse Staff</label>
                <select class="form-control" v-model="user_id">
                    <option value="0">Select</option>
                    <option v-for="(person,index) in staffs" :value="person.id" v-bind:key="index">
                        {{person.full_name}}
                    </option>
                </select>
                <span class="text-danger">{{getError('warehouse_staff_id')}}</span>
                <span class="text-success" v-if="loading">Assigning warehouse staff...</span>
            </div>
            <input type="button" class="btn btn-outline-info btn-sm" v-if="!loading"
                   value="Assign Order To Warehouse Staff"
                   @click="assign()">
        </div>
        <div class="col-12" v-else="">
            <p class="text-muted">Name of warehouse staff: <strong>
                {{order.warehouse_staff.user.full_name}}</strong></p>
        </div>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    import forms from '@/forms';
    export default{
        extends: forms,
        props: ['order'],
        data(){
            return {
                staffs: [],
                user_id: 0,
            };
        },
        methods: {
            loadPersons(){
                let app = this;
                this.axios.get(Paths.warehouse_staff.index).then((response) => {
                    app.staffs = response.data;
                });
            },
            assign(){
                let app = this;
                app.startRequest();
                this.axios.post(Paths.order.warehouse.assign, {
                    order_id: this.order.id,
                    warehouse_staff_id: this.user_id,
                }).then((response) => {
                    app.$emit('assigned', response.data);
                    app.clearErrors();
                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {
                    app.endRequest();
                });
            },
            setUserId(){
                if (this.order.status == 6) {
                    this.user_id = this.order.warehouse_staff.user.id;
                }
            }
        },
        mounted(){

            console.log(JSON.stringify(this.order.warehouse_staff));
            this.loadPersons();

        },
        watch: {
            order(){
                this.setUserId();
                console.log(JSON.stringify(this.order.warehouse_staff));
            }
        }
    }
</script>