<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="">Reason for rejection </label>
                <textarea class="form-control" v-model="reason"></textarea>
                <span class="text-danger">{{getError('reason')}}</span>
            </div>
            <div v-if="!loading">
                <input type="button" @click="reject" class="btn btn-danger btn-sm mr-2" value="Reject Order"/>
                <input type="button" class="btn btn-danger btn-sm " @click="$emit('hide')" value="Close Form"/>
            </div>
        </div>
    </div>
</template>
<script>
    import forms from '@/forms';
    import {Paths} from '@/paths';
    export default{
        extends: forms,
        props: ['order_id'],
        data(){
            return {

                reason: '',

            }
        },
        methods: {
            reject(){
                let app = this;
                this.startRequest();
//                console.log(this.order_id);
//
                this.axios.post(Paths.order.reject, {'order_id': this.order_id, 'reason': this.reason}).then(() => {
                    app.$emit('rejected');
                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {
                    app.endRequest();
                });
            }
        }
    }
</script>