<template>
    <div class="table-responsive">
        <table class="table table-striped items">
            <tr>
                <th>Payment Date</th>
                <th>Amount Paid</th>
                <th>Payment Status</th>
            </tr>
            <tr v-for="payment in payments" v-bind:key="payment.id">
                <td>{{payment.payment_date}}</td>
                <td>{{payment.amount_paid|currency}}</td>
                <td v-if="payment.is_partial">Partial</td>
                <td v-else="">Complete</td>

            </tr>
        </table>
    </div>
</template>
<script>
    export default{
        props: ['payments'],
    }
</script>