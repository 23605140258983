/**
 * Created by Asad on 11/25/2019.
 */

export default {

    readCode(code){
        switch (code) {
            case 1:
                return 'Pending Approval';
            case 2:
                return 'Approved';
            case 3:
                return 'Rejected';
            case 4:
                return 'Assigned To Delivery Agent';
            case 5:
                return 'Delivered';
            case 6:
                return'Assigned to warehouse staff';
            case 7:
                return 'Cancelled';
            default:
                return "Invalid Order";
        }
    },
    forApproved(){
        return 2;
    },
    forRejected(){
        return 3;
    },
    forAssigned(){
        return 4;
    },
    forPending(){
        return 1;
    },
    forDelivered(){
        return 5;
    }


}